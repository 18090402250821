import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import axios from 'axios'

require('swiper/swiper.scss');
require('swiper/components/effect-fade/effect-fade.scss');
import { Swiper } from 'swiper';
import SwiperCore, { Navigation, EffectFade, Controller } from 'swiper/core';
SwiperCore.use([Navigation, EffectFade, Controller]);

gsap.registerPlugin(ScrollToPlugin);


import Plyr from "plyr"
require('plyr/dist/plyr.css')

import Scrollbar from "simplebar"

const $ = selector => document.querySelector(selector);
const $$ = selectors => document.querySelectorAll(selectors);
let landingName = ''


function setFooterYear () {
  document.getElementById("footer_year").innerHTML = new Date().getFullYear()
}

// function setPrice (haveDiscount) {
//   landingName = !haveDiscount ? 'Course-30k' : 'Course-15k'
//   if (!haveDiscount) {
//     $('.lead_price--current').remove()
//     $('.lead_price_decrypt').remove()
//   } else {
//     $('.lead_price--old').classList.add('not-active')
//   }
// }
//
// function checkAccess(param) {
//   axios.get(`https://course.wlc.capital/api/get-discount.php?d=${param}`).then(response => {
//     setPrice(response.data.result)
//   })
// }

function getUnparsedURIParam (param) {
  const par = {};
  window.location.search.slice(1).split('&').forEach(elm => {
    if (elm === '') return;
    let spl = elm.split('=');
    par[spl[0]] = (spl.length >= 2 ? spl[1] : true);
  })
  return par[param]
}

const dParam = getUnparsedURIParam('d');

const refParam = new URLSearchParams(window.location.search).get('vHWcZj');
if (refParam === 'YAsakld') {
  $('body').classList.add('noobo');
}

// if (dParam) {
//   checkAccess(dParam)
// } else if (refParam === 'VdSHQFql') {
//   checkAccess(refParam)
// } else if (refParam === 'YAsakld') {
//   setPrice(false)
// } else {
//   setPrice(false)
// }

window.onload = function() {
  setFooterYear()
  const $popupInputs = document.querySelectorAll('.popup_input')
  function validateInputs () {
    for (let i = 0; i < $popupInputs.length; i++) {
      if (!validateInput($popupInputs[i])) {
        $popupInputs[i].parentNode.classList.add('popup_input-container--invalid')
      } else {
        $popupInputs[i].parentNode.classList.remove('popup_input-container--invalid')
      }
    }
  }
  for (let i = 0; i < $popupInputs.length; i++) {
    $popupInputs[i].addEventListener('keyup', e => {
      e.target.setAttribute('data-value', e.target.value)
    })
    $popupInputs[i].addEventListener('blur', validateInputs)
  }

  const validateRules = {
    name: /^[^+\n]{2,}(?:\+[^+\n]{2,})*$/,
    // phone: /^((8|\+374|\+994|\+995|\+375|\+7|\+380|\+38|\+996|\+998|\+993)[\- ]?)?\(?\d{3,5}\)?[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}(([\- ]?\d{1})?[\- ]?\d{1})?$/,
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  }

  function validateInput(input) {
    const name = input.getAttribute('name')
    if (name !== 'phone') return validateRules[name].test(String(input.value).toLowerCase())
    return true
  }

  const $popup = $('#formPopup');
  const $thxPopup = $('#thx');
  const $popupCall = $$('.popup-call');
  const $popupForm = $('.popup_form');

  for (let i = 0; i < $popupCall.length; i++) {
    if ($popupCall[i].classList.contains('noobo_free') && refParam === 'YAsakld') {
      $popupCall[i].addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();
        gsap.to(window, {
          duration: 1.25,
          scrollTo: {
            y: '#free',
            offsetY: $('.header').clientHeight
          }
        })
      });
      continue;
    }
   $popupCall[i].addEventListener('click', () => {
     document.body.setAttribute('data-scrollPosition', window.pageYOffset)
     $popup.style.display = 'block';
     $('.blur-wrapper').classList.add('blur-wrapper--active');
     document.body.scrollTop = +document.body.dataset.scrollPosition
   })
  }

  function closePopup(name) {
    $('#' + name).style.display = 'none';
    $('.blur-wrapper').classList.remove('blur-wrapper--active');
    document.documentElement.scrollTop = document.body.getAttribute('data-scrollPosition')
    if (name !== 'thx') {
      for (let i = 0; i < $popupInputs.length; i++) {
        $popupInputs[i].value = '';
        $popupInputs[i].parentNode.classList.remove('popup_input-container--invalid')
      }
    }
  }

  $popup.querySelector('.popup_close').addEventListener('click', () => { closePopup('formPopup') });
  $popup.querySelector('.popup_overlay').addEventListener('click', () => { closePopup('formPopup') });
  $thxPopup.querySelector('.popup_close').addEventListener('click', () => { closePopup('thx') });
  $thxPopup.querySelector('.popup_overlay').addEventListener('click', () => { closePopup('thx') });

  // $('#lead_btn-sell').addEventListener('click', () => {
  //   window.open('https://whitelist.getcourse.ru/study2', '_blank')
  // })

  const leadLinks = $$('.btn-sell');

  for(let i = 0; i < leadLinks.length; i++) {
    leadLinks[i].addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();
      gsap.to(window, {
        duration: 1.25,
        scrollTo: {
          y: '#lead',
          offsetY: $('.header').clientHeight
        }
      })

    })
  }

  const freeCourseButtons = $$('.free-course-button');
  const sectionFree = $("#free");

  for(let i = 0; i < freeCourseButtons.length; i++) {
    freeCourseButtons[i].addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();
      gsap.to(window, {
        duration: 1.25,
        scrollTo: {
          y: '#free',
          offsetY: $('.header').clientHeight
        }
      })
    })
  }

  $popupForm.addEventListener('submit', e => {
    e.preventDefault();
    validateInputs();
    if (!$popupForm.querySelectorAll('.popup_input-container--invalid').length) {
      const name = $popupForm.querySelector('#name').value
      const phone = $popupForm.querySelector('#number').value
      const email = $popupForm.querySelector('#email').value
      const requestBody = { name, phone, email, landingName }
      axios.post('https://course.wlc.capital/api/send-mail.php', requestBody).then(response => {
        if (response.data.result) {
          closePopup('formPopup');
          document.body.setAttribute('data-scrollPosition', window.pageYOffset);
          $thxPopup.style.display = 'block';
          $('.blur-wrapper').classList.add('blur-wrapper--active');
          document.body.scrollTop = +document.body.dataset.scrollPosition;
        }
      })
    }
  })

  // function animation(isEnd) {
  //   const ahead1 = gsap.to('.ahead_bg', {
  //     rotation: 360,
  //     repeat: -1,
  //     duration: 5,
  //     ease: 'none'
  //   })
  //   const ahead2 = gsap.to('.ahead_bg_wrapper', {
  //     rotation: -360,
  //     repeat: -1,
  //     duration: 5,
  //     ease: 'none'
  //   })
  //   const ahead3 = gsap.to('.ahead_bg_img--1', {
  //     x: 15,
  //     repeat: -1,
  //     duration: 4,
  //     ease: 'none',
  //     yoyo: true
  //   })
  //   const ahead4 = gsap.to('.ahead_bg_img--2', {
  //     y: 15,
  //     repeat: -1,
  //     duration: 8,
  //     ease: 'none',
  //     yoyo: true
  //   })
  //   const ahead5 = gsap.to('.ahead_bg_img--3', {
  //     x: -15,
  //     repeat: -1,
  //     duration: 6,
  //     ease: 'none',
  //     yoyo: true
  //   })
  //   const ahead6 = gsap.to('.ahead_bg_img--4', {
  //     y: -15,
  //     repeat: -1,
  //     duration: 5,
  //     ease: 'none',
  //     yoyo: true
  //   })

  //   // if (!isEnd) {
  //   //  // ahead1.kill()
  //   //  // ahead2.kill()
  //   //  // ahead3.kill()
  //   //  // ahead4.kill()
  //   //  // ahead5.kill()
  //   //  // ahead6.kill()
  //   //  ahead1.play()
  //   //  ahead2.play()
  //   //  ahead3.play()
  //   //  ahead4.play()
  //   //  ahead5.play()
  //   //  ahead6.play()
  //   // } else {
  //   //  ahead1.pause()
  //   //  ahead2.pause()
  //   //  ahead3.pause()
  //   //  ahead4.pause()
  //   //  ahead5.pause()
  //   //  ahead6.pause()
  //   // }
  // }

  // function callAnimation() {
  //   if (window.innerWidth >= 1200) {
  //     animation(false)
  //   } else {
  //     animation(true)
  //   }
  // }
  // callAnimation()

  // window.addEventListener('resize', callAnimation);

  const preSlider = new Swiper('.program_pre-slider .swiper-container', {
    speed: 400,
    slidesPerView: 15,
    navigation: {
      nextEl: $('.program_pre-slider_navigation-btn--next'),
      prevEl: $('.program_pre-slider_navigation-btn--prev'),
    },
    breakpoints: {
      320: {
        slidesPerView: 3
      },
      480: {
        slidesPerView: 4
      },
      560: {
        slidesPerView: 6
      },
      650: {
        slidesPerView: 8
      },
      768: {
        slidesPerView: 10
      },
      960: {
        slidesPerView: 12
      },
      1004: {
        slidesPerView: 15
      }
    }
  });

  const slider = new Swiper('.program_slider .swiper-container', {
    speed: 400,
    slidesPerView: 1,
    lazy: true
  })

  slider.on('slideChange', swiper => {
    preSlider.slideTo(swiper.realIndex, 400, false)
    for (let j = 0; j < preSliderSlides.length; j++) {
      if (swiper.realIndex === j) {
        preSliderSlides[j].classList.add('program_pre-slider_slide--active')
      } else preSliderSlides[j].classList.remove('program_pre-slider_slide--active')
    }
  })

  const preSliderSlides = $$('.program_pre-slider_slide');

  for(let i = 0; i < preSliderSlides.length; i++) {
    if (i === 0) {
      preSliderSlides[i].classList.add('program_pre-slider_slide--active')
    }
    preSliderSlides[i].addEventListener('click', e => {
      e.stopPropagation();
      if (!preSliderSlides[i].classList.contains('program_pre-slider_slide--active')) {
        slider.slideTo(i, 400, false)
        for (let j = 0; j < preSliderSlides.length; j++) {
          if (i === j) {
            preSliderSlides[j].classList.add('program_pre-slider_slide--active')
          } else preSliderSlides[j].classList.remove('program_pre-slider_slide--active')
        }
      } else e.preventDefault()
    });
  }

  const slidesChangeSpeed = 750

  const videos = [...$$(".free_slide-video")].map(video => {
    const player = new Plyr(video.getElementsByTagName("video")[0], {
      controls: [
        'play-large', // The large play button in the center
        'play', // Play/pause playback
        'progress', // The progress bar and scrubber for playback and buffering
        'current-time', // The current time of playback
        'duration', // The full duration of the media
        'mute', // Toggle mute
        'volume', // Volume control
        'settings', // Settings menu
        'fullscreen', // Toggle fullscreen
      ]
    });

    player.on("ready", () => {
      video.getElementsByClassName("plyr__controls")[0].classList.add("swiper-no-swiping")
    })

    return player;
  });

  const freeSlider = new Swiper('.free_slider .swiper-container', {
    speed: 400,
    slidesPerView: 1,
    lazy: true
  })

  const freePaginationButtons = [...$$(".free_pagination-item")];

  freePaginationButtons.forEach((button, i) => {
    button.addEventListener("click", e => {
      freeSlider.slideTo(i);
    })
  })

  freeSlider.on("slideChange", swiper => {
    [...$$(".free_pagination-item__active")].forEach(button => {
      button.classList.remove("free_pagination-item__active");
    });

    videos.forEach(player => {
      player.pause();
    });

    [...$$(".free_slide-about__active")].forEach(about => {
      about.classList.remove("free_slide-about__active");
    });

    [...$$(".free_slide-video__disabled")].forEach(video => {
      video.classList.remove("free_slide-video__disabled");
    });

    freePaginationButtons[swiper.realIndex].classList.add("free_pagination-item__active");
  });


  [...$$(".free_slide")].forEach(slide => {
    const about = slide.getElementsByClassName("free_slide-about")[0];
    const close = slide.getElementsByClassName("free_about-close")[0];
    const lnk = slide.getElementsByClassName("free_slide-lnk_span")[0];
    const video = slide.getElementsByClassName("free_slide-video")[0];
    const wrapper = slide.getElementsByClassName("free_slide-about_wrapper")[0];

    const  scrollable = new Scrollbar(wrapper);


    lnk.addEventListener("click", (e) => {
      about.classList.add("free_slide-about__active");
      slide.classList.add("swiper-no-swiping")
      videos.forEach(video => video.pause());
      video.classList.add("free_slide-video__disabled");
      e.stopPropagation();
    });

    close.addEventListener("click", (e) => {
      scrollable.getScrollElement().scrollTop = 0;
      about.classList.remove("free_slide-about__active");
      video.classList.remove("free_slide-video__disabled");
      slide.classList.remove("swiper-no-swiping");

    })

    about.addEventListener("click", (e) => {
      e.stopPropagation();
    })

    slide.addEventListener("click", (e) => {
      scrollable.getScrollElement().scrollTop = 0;
      about.classList.remove("free_slide-about__active");
      video.classList.remove("free_slide-video__disabled");
      slide.classList.remove("swiper-no-swiping");

    })
  });

  const teachersSlider = new Swiper('#teachers-slider', {
    speed: slidesChangeSpeed,
    loop: true,
    slidesPerView: 1,
    loopAdditionalSlides: 5,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    }
  })


  const nextSlideIndicator = $('#teachersNavNext .teacher_controls_nav_icon_circle circle')

  const nextSlideIndicatorAnimation = gsap.fromTo(nextSlideIndicator,
    {
      strokeDashoffset: 100.4
    },
    {
      strokeDashoffset: 0,
      paused: true,
      duration: 7,
      ease: 'none',
      onComplete () {
        teachersSlider.slideNext(slidesChangeSpeed)
      }
    }
  )

  const teachersControlsSlider = new Swiper('#teachers-control-slider', {
    speed: slidesChangeSpeed,
    loop: true,
    slidesPerView: 1,
    loopAdditionalSlides: 5,
    simulateTouch: false,
    on: {
      slideChange (swiper) {
        teachersSlider.slideToLoop(swiper.realIndex, slidesChangeSpeed)
        nextSlideIndicatorAnimation.pause(0)
        nextSlideIndicatorAnimation.resume()
      }
    }
  })

  teachersSlider.on('slideChange', swiper => {
    teachersControlsSlider.slideToLoop(swiper.realIndex, slidesChangeSpeed)
  })

  /*teachersSlider.on('activeIndexChange', swiper => {

    $$('#teachers-slider .swiper-slide')[swiper.activeIndex].style.opacity = '0.5'
  })*/

  $('#teachersNavNext').addEventListener('click', () => {
    teachersControlsSlider.slideNext(slidesChangeSpeed)
  })
  $('#teachersNavPrev').addEventListener('click', () => {
    teachersControlsSlider.slidePrev(slidesChangeSpeed)
  })

  function toggleMenu() {
    $('.header_menu').classList.toggle('header_menu--active');
    $('.header_toggler').classList.toggle('header_toggler--active');
    $('body').classList.toggle('fixed')
  }

  $('.header_toggler').addEventListener('click', e => {
    e.stopPropagation();
    toggleMenu()
  })
  document.documentElement.addEventListener('click', e => {
    let finded = false
    const eventPath = e.path ?? (e.composedPath && e.composedPath()) ?? [];
    for (let item of eventPath) {
      if (item === $('.header_menu')) finded = true
    }
    if ($('.header_menu').classList.contains('header_menu--active') && !finded) {
      toggleMenu()
    }
  });

  const anchors = $$('.anchor');
  for (let i = 0; i < anchors.length; i++) {
    anchors[i].addEventListener('click', e => {
      e.stopPropagation();
      const eventPath = e.path ?? (e.composedPath && e.composedPath()) ?? [];
      for (let item of eventPath) {
        if (item === $('.header')) toggleMenu()
      }
      gsap.to(window, {
        duration: 1.25, scrollTo:
          { y: anchors[i].dataset.to !== '#ahead'
              ? anchors[i].dataset.to
              : 0,
            offsetY: anchors[i].dataset.to !== '#ahead'
              ? $('.header').clientHeight
              : 0
          }
      })
    });
  }

  [...$$(".accordion")].forEach((element, i) => {
    const accordionAhead = element.querySelector('.accordion_ahead');
    const accordionBody = element.querySelector('.accordion_body');
    const accordionContent = element.querySelector('.accordion_content');
    const accordionBtn = element.querySelector('.accordion_button');

    let currentAnimation = null;
    let isOpen = false;

    gsap.set(accordionBody, {
      height: 0
    })

    window.addEventListener('resize', () => {
      if (isOpen) {
        if (currentAnimation === null) {
          gsap.set(accordionBody, {
            height: accordionContent.clientHeight
          })
        } else {
          currentAnimation.kill();
          currentAnimation = gsap.to(accordionBody, {
            duration: 0.2,
            height: accordionContent.clientHeight,
            ease: 'linear',
            onComplete: () => {
              currentAnimation = null
            }
          })
        }
      }
    })

    const switchItem = () => {
      isOpen = !isOpen
      if (currentAnimation !== null) {
        currentAnimation.kill()
      }

      accordionBtn.classList.toggle('asdf_switch__active', isOpen);
      currentAnimation = gsap.to(accordionBody, {
        duration: 0.2,
        height: isOpen ? accordionContent.clientHeight : 0,
        ease: 'linear',
        onComplete: () => {
          currentAnimation = null
        }
      })
    }

    accordionAhead.addEventListener('click', () => {
      switchItem();
    })
  })

  const pupilImgSwiper = new Swiper('#pupil_img-swiper', {
    modules: [EffectFade],
    effect: "fade",
    fadeEffect: {
      crossFade: true
    },
    speed: 200,
    loop: true,
    allowTouchMove: false
  })

  const pupilSwiper = new Swiper('#pupil_swiper', {
    modules: [Controller],
    speed: 200,
    navigation: {
      prevEl: '#pupilNavPrev',
      nextEl: '#pupilNavNext'
    },
    loop: true,
    simulateTouch: false,
    controller: {
      control: pupilImgSwiper
    }
  })

  let tas = [...$$('.tas')];
  let ta =[...$$('.ta')];
  if (window.innerWidth < 960) {
    tas.forEach(item  => item.classList.add('appearence_self'));
    ta.forEach(item => item.classList.add('appearence'));

    tas = [];
    ta = [];
  }

  const asffff = () => {
    if (window.innerWidth < 960) return;

    tas = tas.filter(item => {
      if ((+window.innerHeight * 0.8) > item.getBoundingClientRect().top) {
        item.classList.add('appearence_self');
        return false
      }
      return true
    })
    ta = ta.filter(item => {
      if ((+window.innerHeight * 0.8) > item.getBoundingClientRect().top) {
        item.classList.add('appearence');
        return false;
      }
      return true;
    })
  }

  window.addEventListener('scroll', asffff)
  asffff()

}

function menuItemsLightning () {
  const items = [];
  const headerLinks = $$('.header_nav_link');
  [].forEach.call(headerLinks, item => {
    items.push(item.dataset.to)
  });
  const itemsPositions = items.reduce((arr, curr) => {
    arr.push($(curr).offsetTop);
    return arr
  }, []);
  const winPos = window.pageYOffset;
  let curr = undefined
  itemsPositions.forEach((item, index) => {
    if (winPos + $('.header').clientHeight > item) curr = index
  });
  if (curr !== undefined) {
    for (let i = 0; i < headerLinks.length; i++) {
      if (curr === i) {
        headerLinks[i].classList.add('active')
      } else {
        headerLinks[i].classList.remove('active')
      }
    }
  } else {
    for (let i = 0; i < headerLinks.length; i++) {
        headerLinks[i].classList.remove('active')
    }
  }
}

window.addEventListener('scroll', menuItemsLightning);
menuItemsLightning()
